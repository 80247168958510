import type {LinkField, KeyTextField} from '@prismicio/types';
import {useTypeGuards} from './useTypeGuards';

const {hasProperty, isFilled} = useTypeGuards();

function hasText(value: unknown) {
    return hasProperty(value, 'text') && isFilled(value.text);
}

function isLink(textLink: KeyTextField, link?: LinkField | null): boolean {
    return !!link && link.link_type !== 'Any' && textLink !== null;
}

export const usePrismicFieldHelpers = () => {
    return {
        hasText,
        isLink,
    };
};
